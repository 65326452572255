/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import './Loading.css';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';

export const Loading = () => {
  return (
    <div className="loading-container">
      <h1 className="loading-text">Coming Soon...</h1>
      <div className="home-gradient-bar"></div>
    </div>
  );
};

export default Loading;

/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import './ResetPassword.css';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const ResetPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const location = useLocation();

  // Retrieve the token from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Axios.post(
          `${baseUrl}/password-reset/reset`,
          { newPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
      );
      alert(response.data.message);
      navigate('/login');
    } catch (error) {
      console.error(error);
      alert('An error occurred while resetting the password.');
    }
  };


  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);


  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <h2>Reset Password</h2>
        <label htmlFor="newPassword" className="new-password-label">New Password</label>
        <input
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          id="newPassword"
          name="newPassword"
          required
        />
        <button type="submit" className="reset-button">Reset Password</button>
      </form>
      <div className="gradient-bar"></div> {/* Gradient bar now at the top */}

      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

    </div>
  );
};

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* VerifyEmail.js */
import React, {useEffect} from 'react';
import {useLocation, Link} from 'react-router-dom';
import Axios from 'axios';
import './VerifyEmail.css';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const VerifyEmail = () => {
  const location = useLocation();
  const {username, password} = location.state || {}; // Ensure state is defined
  const Base_url = process.env.REACT_APP_BASE_URL;

  const resendEmail = async () => {
    try {
      const response = await Axios.post(`${Base_url}/Login`, {username, password});
      alert('Verification email resent.');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <div className="verify-email-page">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Seda Logo" className="logo" /> {/* Logo image */}
        </Link>
      </div>
      <div className="verify-email-container">
        <h2>Verify Your Email</h2>
        <p>We’ve sent an Email to {username}.<br />Click the link inside to get Verified. <br /> Once you're verified, <Link to="/login" className="login-link">Log In</Link> to Get Started</p>
        <p>Didn’t get an Email? <br /> <button className="resend-button" onClick={resendEmail}>Resend Email</button></p>
      </div>
      <div className="gradient-bar"></div> {/* Gradient bar */}
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

    </div>
  );
};

/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './VerificationStatus.css';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const VerificationStatus = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();

  const [statusMessage, setStatusMessage] = useState('');

  // Retrieve the status from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');

  useEffect(() => {
    switch (status) {
      case 'success':
        setStatusMessage('Email Verified Successfully!');
        break;
      case 'expired':
        setStatusMessage('Your token has expired. Please request a new one.');
        break;
      case 'invalid':
        setStatusMessage('Invalid token. Please check your email or request a new one.');
        break;
      default:
        setStatusMessage('An error occurred. Please try again later.');
    }
  }, [status]);

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  const handleGoToLogin = () => {
    navigate('/Login');
  };

  return (
    <div className="verification-status-container">
      <div className="verification-status-box">
        <h2>{statusMessage}</h2>
        <button onClick={handleGoToLogin} className="verification-button">Go to Login</button>
      </div>
      <div className="gradient-bar"></div> {/* Gradient bar now at the top */}

      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />
    </div>
  );
};
